import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { withPrefix } from 'gatsby'


import Layout from '../components/Layout'
import ArticleRoll from '../components/ArticleRoll'

export const IndexPageTemplate = ({
}) => (
  <ArticleRoll />
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
}

const IndexPage = () => {
  return (
    <Layout activePage='home'>
      <div className="logo-mobile">
        <img src={`${withPrefix('/')}img/logomark-white.svg`} />
      </div>
      <IndexPageTemplate />
    </Layout>
  )
}


export default IndexPage
