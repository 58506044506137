import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { withPrefix } from 'gatsby'

class ArticleRoll extends React.Component {
  constructor(props) {
    super();
    this.posts = [];
    const { data } = props
    this.posts = data.allMarkdownRemark.edges.slice();
    // sort the featured post so most recent is at front of array
    this.posts.sort((a, b) => b.node.frontmatter.date - a.node.frontmatter.date);
    this.featured = this.posts.splice(0, 2);
  }
  
  render() {
    // reduce the featured array to last two eles
    return (
      <div className="article-roll-container">
        <div className="articles-featured">
          {
            this.featured && this.featured.map((article) =>
              ArticleTile(article.node)
            )
          }
        </div>
        <div className="articles-not-featured">
          {
            this.posts && this.posts.map((post) =>
              ArticleTile(post.node)
            )
          }
        </div>
      </div>
    )
  }
}


let ArticleTile = (post) => {
  return (
    <Link to={post.fields.slug} className="featured-article" key={post.id}>
      {post.frontmatter.featuredimage ? (
        <div
          className="featured-article-thumbnail"
        >
          <PreviewCompatibleImage
            imageInfo={{
              image: post.frontmatter.featuredimage,
              alt: `featured image thumbnail for post ${post.frontmatter.title}`, // TODO: Actual alt text
            }}
          />

        </div>
      ) : null}
      <h2 className="featured-article-title">
        {post.frontmatter.title}
      </h2>
      <div className="featured-article-hover-tile" aria-hidden="true">
        <h2 className="featured-article-hover-title">
          {post.frontmatter.title}
        </h2>
        <h3 className="featured-article-hover-author">
          by {post.frontmatter.author}
        </h3>
      </div>
      <div className="article-meta-mobile" aria-hidden="true">
        <h2 className="article-title-mobile">
          {post.frontmatter.title}
        </h2>
        <h3 className="article-author-mobile">
          by {post.frontmatter.author}
        </h3>
      </div>
    </Link>
  )
}

ArticleRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ArticleRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "article" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                author
                tags
                featuredimage {
                  childImageSharp {
                    fluid(
                      maxWidth: 700,
                      maxHeight: 600,
                      quality: 100,
                      cropFocus: CENTER,
                      duotone: { highlight: "#01dbfe", shadow: "#7f01d3"},
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ArticleRoll data={data} count={count} />}
  />
)
